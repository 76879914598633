import Footer from "./components/Footer";
import Projects from "./pages/Projects";
import Contacts from "./pages/Contacts";
import ScrollSpy from "react-ui-scrollspy";
import ProgressBar from "react-scroll-progress-bar";
import { useState } from "react";
import Skills from "./components/Skills";
import Header from "./components/Header";

import "./styles/Main.styles.scss";

const App = () => {

	const [activeLink, setActiveLink] = useState("skills");

	return (
		<div className="App">
			<ProgressBar height="5" bgcolor="#1c2414" duration="0.2" className="progress-bar" />
			<Header/>
			<nav>
        		<a href="#skills" 
					data-to-scrollspy-id="skills" 
					onClick={() => setActiveLink("skills")} 
					className={`${activeLink === "skills" ? "active" : ""}`}
				>Skills</a>
        		<a href="#projects" 
					data-to-scrollspy-id="projects" 
					onClick={(e) => setActiveLink("projects")} 
					className={`${activeLink === "projects" ? "active" : ""}`}
				>Projects</a>
        		<a href="#contacts" 
					data-to-scrollspy-id="contacts" 
					onClick={(e) => setActiveLink("contacts")} 
					className={`${activeLink === "contacts" ? "active" : ""}`}
				>Contacts</a>
			</nav>
			<div className="container">
				<ScrollSpy onUpdateCallback={(e) => {
					setActiveLink(e);
				}}>
					<Skills/>
					<Projects/>
					<Contacts/>
				</ScrollSpy>
			</div>
    		<Footer/>
    	</div>
	);
}

export default App;
