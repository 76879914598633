import { ReactComponent as JS } from "../static/icons/skills/JS.svg";
import { ReactComponent as HTML5} from "../static/icons/skills/HTML5.svg";
import { ReactComponent as CSS3 } from "../static/icons/skills/CSS3.svg";
import { ReactComponent as React } from "../static/icons/skills/React.svg";
import { ReactComponent as Vue } from "../static/icons/skills/Vue.svg";

import { ReactComponent as Node } from "../static/icons/skills/Node.svg";
import { ReactComponent as Nest } from "../static/icons/skills/Nest.svg";
import { ReactComponent as PostGreSQL } from "../static/icons/skills/PostGreSQL.svg";
import { ReactComponent as Mongo } from "../static/icons/skills/Mongo.svg";
import { ReactComponent as Next } from "../static/icons/skills/Next.svg";

const Skills = () => {
    return (
        <section id="skills">
            <h1>My Skills</h1>
            <div className="frontend">
                <h2>Frontend</h2>
                <ul className="list">
                    <li><React/><span>React</span></li>
                    <li><JS/><span>JS</span></li>
                    <li><HTML5/><span>HTML5</span></li>
                    <li><CSS3/><span>CSS3</span></li>
                    <li><Vue/><span>Vue</span></li>
                </ul>
            </div>
            <div className="backend">
                <h2>Backend</h2>
                <ul className="list">
                    <li><Next/><span>NextJS</span></li>
                    <li><Node/><span>NodeJS</span></li>
                    <li><Nest/><span>NestJS</span></li>
                    <li><PostGreSQL/><span>SQL</span></li>
                    <li><Mongo/><span>MongoDB</span></li>
                </ul>
            </div>
        </section>
    )
}

export default Skills;