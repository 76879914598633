import { ReactComponent as Telegram } from "../static/icons/Telegram.svg";
import { ReactComponent as Viber } from "../static/icons/Viber.svg";
import { ReactComponent as Gmail } from "../static/icons/Gmail.svg";
import { ReactComponent as LinkedIn } from "../static/icons/LinkedIn.svg";
import { ReactComponent as Location } from "../static/icons/Location.svg";
import { ReactComponent as GitHub } from "../static/icons/GitHub.svg";
const Contacts = () => {

    return (    
    <section id="contacts">
        <h1>Contacts</h1>
        <ul>
            <li>
                <a href="mailto:koshelnyi.vladyslav@gmail.com">
                    <div className="icons">
                        <Gmail/>
                    </div>
                    <span>koshelnyi.vladyslav@gmail.com</span>
                </a>
            </li>
            <li>
                <a href="https://github.com/LaDunne15" target="_blank" rel="noreferrer">
                    <div className="icons">
                        <GitHub/>
                    </div>
                    <span>GitHub</span>
                </a>
                <a href="https://www.linkedin.com/in/vladyslav-koshelnyi-a56226265/" target="_blank" rel="noreferrer">
                    <div className="icons">
                        <LinkedIn/>
                    </div>
                    <span>LinkedIn</span>
                </a>
            </li>
            <li>
                <a href="tel:+380991743192">
                    <div className="icons">
                        <Telegram/>
                        <Viber/>
                    </div>
                    <span>+380 (99) 174-31-92</span>
                </a>
            </li>
            <li>
                <a href="https://en.wikipedia.org/wiki/Chernivtsi" target="_blank" rel="noreferrer">
                    <div className="icons">
                        <Location/>
                    </div>
                    <span>Chernivtsi, Ukraine</span>
                </a>
            </li>
        </ul>
    </section>
    );
}
export default Contacts;