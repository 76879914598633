import iconQ from "../projects/quizaida/favicon.ico";
import iconFC from "../projects/filmCheck/favicon.png";
import iconP from "../projects/pogodka/favicon.png";

import Q1 from "../projects/quizaida/images/photo_2024-05-25_16-01-09.jpg";
import Q2 from "../projects/quizaida/images/photo_2024-05-25_16-01-15.jpg";
import Q3 from "../projects/quizaida/images/photo_2024-05-25_16-01-21.jpg";
import Q4 from "../projects/quizaida/images/photo_2024-05-25_16-01-25.jpg";
import Q5 from "../projects/quizaida/images/photo_2024-05-25_16-01-29.jpg";

import FC1 from "../projects/filmCheck/images/Actor.png";
import FC2 from "../projects/filmCheck/images/SearchResult.png";
import FC3 from "../projects/filmCheck/images/titleDesktop.png";
import FC4 from "../projects/filmCheck/images/titleMobile.png";

import P1 from "../projects/pogodka/images/Desktop.jpg";
import P2 from "../projects/pogodka/images//Mobile.jpg";

const data = [
    {
        title: "Quizaida",
        icon: iconQ,
        url: "https://quizaida.vercel.app",
        stack: [
            "Next.js","MongoDB"
        ],
        gitHub: "https://github.com/LaDunne15/quizaida",
        imgs: [
            Q3, Q4, Q5, Q1, Q2
        ],
        description: "Website for quiz tests"
    }, 
    {
        title: "FilmCHECK",
        icon: iconFC,
        url: "https://film-check.onrender.com/",
        stack: [
            "React","Redux"
        ],
        gitHub: "https://github.com/LaDunne15/film-check",
        imgs: [
            FC1, FC2, FC3, FC4
        ],
        description: "Information about films, serials and actors"
    }, 
    {
        title: "Як там внизу погодка?",
        icon: iconP,
        url: "https://pogodka.pp.ua",
        stack: [
            "React","Redux"
        ],
        gitHub: "https://github.com/LaDunne15/weather-forecast",
        imgs: [
            P1, P2
        ],
        description: "Weather report website"
    },
    /*
    {
        title: "Брусницький ЗЗСО І-ІІІ ступенів",
        url: "https://brusnitsa-school-landing.onrender.com",
        stack: [
            "HTML5","CSS3","JS"
        ],
        gitHub: "https://github.com/LaDunne15/school-landing",
        imgs: [
        ],
        description: "School site concept redesign"
    }*/
];

export default data;