import fileCV from "../static/files/CV.pdf";
import img from "../static/images/image.png";

const Header = () => {

    return (
        <header>
            <div className="primary">
                <p className="greetings">Greetings!</p>
                <p className="text">My name is </p>
                <img src={img} alt="logo" className="logo"/>
                <p className="name">Vladyslav</p>
                <p className="job">a fullstack developer</p>
                <p className="add">with passion to do various interesting things</p>
            </div>
            <a className="cv" href={fileCV} download="CV" target="_blank" rel="noreferrer">
                <span>Download CV</span>
            </a>
        </header>
    );
}



export default Header;