import { useEffect, useState } from 'react';
import data from "../static/data/projectList";
import { ReactComponent as GitHub } from "../static/icons/GitHub.svg";
import { ReactComponent as Link } from "../static/icons/Link.svg";
const Projects = () => {

    const [projects, setProjects] = useState([]);

    const [image, setImage] = useState("");

    useEffect(() => {
        setProjects(data);
    }, []);

    return (
        <section id="projects">
            <h1>My Projects</h1>
            <div className={`overlay ${image?"visible":""}`}>
                <div className='popup'>
                    <img src={image} alt="No"/>
                </div>
                <button className="close" onClick={() => setImage("")}>&times;</button>
            </div>
            <ul className="projects-list">
                {
                    projects.map( p => <li key={p.title} className='project-item'>
                        <div className='main'>
                            <img src={p.icon} alt='NNNn'/>
                            <h2>{p.title}</h2>
                        </div>
                        <h3>{p.description}</h3>
                        <ul className='links'>
                            <li>
                                <a href={p.url} target="_blank" rel="noreferrer">
                                    <Link/>
                                    <span>{ p.url }</span>
                                </a>
                            </li>
                            {
                                p.gitHub && <li>
                                    <a href={p.gitHub} target="_blank" rel="noreferrer">
                                        <GitHub/>
                                        <span>{ p.gitHub }</span>
                                    </a>
                                </li>
                            }
                        </ul>
                        <ul className='stack'>
                            {
                                p.stack.map( i => <li key={i}>
                                    {i}
                                </li>)
                            }
                        </ul>
                        <ul className='images'>
                            {
                                p.imgs.map( i => <li key={i} onClick={() => setImage(i)}>
                                    <img src={i} alt={i}/>
                                </li>)
                            }
                        </ul>
                    </li>)
                }
            </ul>
        </section>
    );
}
export default Projects;