const Footer = () => {

    return (
        <footer>
            <cite>
                Small steps today lead to a great journey tomorrow
            </cite>
            <p>2023</p>
            <p>Handcrafted by me ©osh</p>
        </footer>
    );
}



export default Footer;
